import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`GitHub's aim is to be the home for `}<em parentName="p">{`all`}</em>{` developers. To be inclusive means we must consider accessibility at the core of how we design.`}</p>
    <p>{`16% of the world's population has a disability (estimated by the `}<a parentName="p" {...{
        "href": "https://www.who.int/en/news-room/fact-sheets/detail/disability-and-health"
      }}>{`World Health Organization`}</a>{`), and that doesn't take into consideration those under temporary or situational disabilities.`}</p>
    <p>{`In many cases, devices that have been designed with accessibility in mind for a portion of the population end up benefiting everyone. Take the `}<a parentName="p" {...{
        "href": "https://www.oxo.com/blog/behind-the-scenes/behind-design-oxos-iconic-good-grips-handles/"
      }}>{`OXO brand's Good Grips line of products`}</a>{`; although they were developed for people who have arthritis, most people found them easier to use than other product lines.`}</p>
    <p>{`When you consider accessibility from the beginning of the design process and incorporate it throughout, it becomes part of an established design process that seeks to include everyone, of all abilities, and allows everyone to achieve more.`}</p>
    <Note mdxType="Note">
  When reading through accessibility information, you might find the common abbreviation: "a11y". A11y is a shortening
  of the word "accessibility". It starts with the first letter, "a", uses the number 11 for the eleven characters
  inbetween the first and last letter, and ends with the last letter, "y".
    </Note>
    <h2>{`What standards you must aim for`}</h2>
    <p>{`GitHub aims for `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`Web Content Accessibility Guidelines (WCAG) 2.1`}</a>{` AA compliance. This includes all of WCAG 2.0 AA plus additional considerations.`}</p>
    <p>{`Currently, `}<a parentName="p" {...{
        "href": "https://www.access-board.gov/guidelines-and-standards/communications-and-it/about-the-ict-refresh/overview-of-the-final-rule"
      }}>{`Section 508`}</a>{` (required by law in the United States) follows WCAG 2.0 AA guidelines for compliance.`}</p>
    <h2>{`Who should you consider when designing?`}</h2>
    <p>{`While there are a multitude of disabilities to consider, the majority will fall into one of these categories:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Visual:`}</strong>{` Anything that deals with sight, or technology that helps someone to see. This includes people who have different types of sight like color blindness.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cognitive:`}</strong>{` Concentration, memory, judgement, problem solving, logic skills.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mobility:`}</strong>{` Anything that affects movement in a body.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hearing:`}</strong>{` A spectrum of disabilities related to sound or audio.`}</li>
    </ul>
    <p>{`Each disability category can be further divided into three sub-categories:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Permanent:`}</strong>{` Disability does not go away.`}
        <ul parentName="li">
          <li parentName="ul">{`Example: Someone who is deaf`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Temporary:`}</strong>{` The disability will go away in time.`}
        <ul parentName="li">
          <li parentName="ul">{`Example: Someone who has an ear infection`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Situational:`}</strong>{` The environment someone is in plays a huge factor in how they interact with web content.`}
        <ul parentName="li">
          <li parentName="ul">{`Example: Someone who is at a loud sporting event`}</li>
        </ul>
      </li>
    </ul>
    <p>{`For an informative list on real-life disability situations, check `}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20230307004829/https://the-pastry-box-project.net/anne-gibson/2014-july-31"
      }}>{`An Alphabet of Accessibility Issues`}</a>{`, published by The Pastry Box Project.`}</p>
    <p>{`Microsoft has created a downloadable PDF that looks into the area of inclusive design from a people perspective, with several informative examples. Navigate to the ‘Inclusive 101’ toolkit on the main page of `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/design/inclusive/"
      }}>{`Microsoft’s Inclusive Design website`}</a>{`.`}</p>
    <h2>{`Internal resources`}</h2>
    <p>{`If you're GitHub staff and need help with accessibility:`}</p>
    <ul>
      <li parentName="ul">{`Visit the #accessibility Slack channel to ask questions or discuss accessibility issues`}</li>
      <li parentName="ul">{`Check the `}<a parentName="li" {...{
          "href": "https://github.com/github/accessibility"
        }}>{`github/accessibility`}</a>{` repository (accessible to GitHub staff only) for information on events or learning resources`}</li>
      <li parentName="ul">{`Attend the inclusive design office hours with @ichelsea on Tuesdays (the time alternates between 10am PST and 1pm PST every other week)`}</li>
    </ul>
    <h2>{`Support`}</h2>
    <p>{`Accessibility is a priority for GitHub. If you ever encounter accessibility related issues when using github.com, please don’t hesitate to get in touch via email `}<a parentName="p" {...{
        "href": "mailto:support@github.com"
      }}>{`support@github.com`}</a>{` with your concerns.`}</p>
    <p>{`For information about the accessibility compliance of GitHub products, please refer to the `}<a parentName="p" {...{
        "href": "https://government.github.com/accessibility/"
      }}>{`VPAT report, outlining §508 accessibility information for GitHub.com, GitHub Enterprise, and GitHub Desktop`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      